<template>
  <div class="row text-left">
    <div class="col-md-12">
      <div class="card-conteudo">
        <div class="clearfix">
          <h4 class="float-left">FUNÇÃO</h4>
        </div>
        <br>
        <div class="row">
          <div class="col-md-6" style="width: 1250px">
            <div class="form-group">
              <p>* Fuções que participarão deste evento</p>

              <div class="float-right mb-2">
                <b-input-group>
                  <b-input
                      class="form-control"
                      placeholder="Buscar..."
                      v-model="search"
                      v-on:keyup.enter="getSearch">
                  </b-input>
                  <b-input-group-append>
                    <b-button variant="outline-secondary" @click="getSearch"><i class="fa fa-search"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>

              <table class="table table-striped table-bordered nowrap" cellspacing="0" width="100%">
                <thead>
                  <tr>
                    <td>Função</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="fun in functions" :key="fun.id">
                      <td>{{fun.descricao}}</td>
                      <td v-if="fun.evento_funcao">
                        <button class="btn btn-sm btn-outline-secondary" disabled>Escolhido</button>
                      </td>
                      <td v-else>
                        <button class="btn btn-sm btn-outline-tema-roxo"
                                @click="setFunction(fun.id)"
                        >
                          Escolher
                        </button>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-1 p-3" v-if="totalPage > 1">
              <paginate
                  :pageCount="totalPage"
                  :clickHandler="setPage"
                  container-class="pagination justify-content-center"
                  page-class="page-item"
                  page-link-class="page-link"
                  prev-class="page-item"
                  prev-link-class="page-link"
                  next-class="page-item"
                  next-link-class="page-link"
                  prev-text="<<"
                  next-text=">>"
              >
              </paginate>
            </b-row>
          </div>

          <div class="col-md-6" id="mt-2" style="border-left: 1px solid #d9d9d9; padding-top: 10px">
            <h6 class="mt-45">Funções adicionadas</h6>

            <div class="row">
              <div class="col-md-12 mt-1">
                <b-table
                    id="my-functions-add"
                    :items="store.data"
                    :per-page="store.perPage"
                    :current-page="store.currentPage"
                    striped
                    :fields="store.fields"
                >

                  <template #cell(id)="data">
                    <button class="btn btn-sm btn-outline-danger mr-2"
                            @click="setRemove(data.item.id, data.index)"
                    >
                      Remover
                    </button>

                    <button v-if="data.item.conta_quorum" class="btn btn-sm btn-outline-tema-roxo"
                            @click="setSaveQuorum(data.item.id, false, data.index)"
                    >
                      Conta Quorum Ativo
                    </button>

                    <button v-else class="btn btn-sm btn-outline-danger"
                            @click="setSaveQuorum(data.item.id, true, data.index)"
                    >
                      Conta Quorum Inativo
                    </button>
                  </template>

                </b-table>

                <b-pagination
                    v-model="store.currentPage"
                    :total-rows="storeRows"
                    :per-page="store.perPage"
                    aria-controls="my-functions-add"
                    prev-text="<<"
                    next-text=">>"
                    first-number
                    last-number8
                ></b-pagination>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Paginate from 'vuejs-paginate'
import setNotification from "@/config/showNotifications";

export default {
  name: "TabFunctions",
  props: ['item'],
  created() {
    this.setValue()
  },
  data: () => ({
    event_id: null,
    search: null,
    totalPage: null,
    currentPage: null,
    store: {
      fields: [
          {key: 'funcao.descricao', label: 'Função'},
          {key: 'id', label: ''}
      ],
      data: [],
      currentPage: 1,
      perPage: 10
    },
    functions: []
  }),
  computed: {
    storeRows() {
      return this.store.data.length
    }
  },
  methods: {
    ...mapActions('events', [
        'getFunctions',
        'setStoreFunction',
        'setQuorum',
        'setDestroyFunction'
    ]),
    async get() {
      let data = await this.getFunctions({id: this.event_id, search: this.search, page: this.currentPage})

      if (data) {
        this.functions = data.data
        this.totalPage = data.lastPage
        this.currentPage = data.page
      }
    },

    setValue() {
      let data = this.item
      if (data) {
        this.event_id     = data.id
        this.currentPage  = 1
        this.store.data   = data.evento_funcoes
        this.get()
      }
    },

    async setFunction(id) {
      let data = await this.setStoreFunction(
          {
            event_id: this.event_id,
            function_id: id
          })

      if (data) {
        this.store.data.push(data)
        await this.get()
        setNotification(this, 'success', 'Função adicionada')
      } else {
        setNotification(this, 'error', 'Um erro inesperado aconteceu')
      }
    },

    async setSaveQuorum(id, bool, index) {
      let data = await this.setQuorum({id: id, quorum: bool})

      if (data) {
        this.store.data[index].conta_quorum = bool
        setNotification(this, 'success', 'sucesso')
      } else {
        setNotification(this, 'error', 'Um erro inesperado aconteceu')
      }
    },

    async setRemove(id, index) {
      let data = await this.setDestroyFunction({id: id})

      if (data) {
        this.store.data.splice(index, 1)
        await this.get()
        setNotification(this, 'success', 'Função removida')
      } else {
        setNotification(this, 'error', 'Um erro inesperado aconteceu')
      }
    },

    setPage(page) {
      this.currentPage = page
      this.get()
    },

    getSearch() {
      this.currentPage = 1
      this.totalPage   = 0
      this.get()
    },


  },
  components: {Paginate}
}
</script>

<style scoped>
  .btn.btn-outline-tema-roxo {
    color: #5d0479 !important;
  }
  .btn.btn-outline-tema-roxo:hover {
    color: #FAFAFA !important;
  }
  .mt-45{
    margin-top: 2.5rem;
  }
</style>