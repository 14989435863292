<template>
  <div>
    <b-modal ref="modal-people" hide-footer id="modal-people" title="Pessoas">
      <div class="d-block text-center">

        <b-col md="12">
          <b-input-group>
            <b-input class="form-control" placeholder="Buscar..." v-model="search" v-on:keyup.enter="get"></b-input>
            <b-input-group-append>
              <b-button variant="outline-secondary" @click="get">
              <i class="fa fa-search"></i>
            </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col md="12 mt-3 text-left">

          <b-table
              id="table-people-add"
              :items="store.data"
              :per-page="store.perPage"
              :current-page="store.currentPage"
              striped
              :fields="store.fields"
          >

            <template #cell(nome)="data">
              <img class="img-round-table" :src="getImg(data.item.foto)">
              &nbsp;&nbsp;<span>{{ data.item.nome  }}</span>
            </template>

            <template #cell(id)="data">
              <button class="btn btn-outline-primary btn-sm" @click="setAdd(data.index)">Adicionar</button>
            </template>

          </b-table>

          <b-pagination v-if="store.data.length > 10"
                        v-model="store.currentPage"
                        :total-rows="storeRows"
                        :per-page="store.perPage"
                        aria-controls="table-people-add"
                        first-number
                        last-number
          ></b-pagination>

        </b-col>



      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: "ModalPeople",
  data: () => ({
    store: {
      fields: [
        {key: 'nome', label: 'Nome'},
        {key: 'entidade_id', label: 'Instituição'},
        {key: 'id', label: ''}
      ],
      data: [],
      currentPage: 1,
      perPage: 10
    },
    search: null,
    path: 'https://sistemas.usb.org.br'
  }),
  computed: {
    storeRows() {
      return this.store.data.length
    }
  },
  methods: {
    ...mapActions('events', ['getPeople']),

    async get() {
      let data = await this.getPeople({search: this.search})

      if (data) {
        this.store.data = data
        this.store.currentPage = 1
      }
    },

    getImg(img) {
      return img ? this.path + '/uploads/sistemasusb/usuarios/'+ img : this.path + '/uploads/sistemasusb/usuarios/avatar-default.png'
    },

    setAdd(index) {
      this.$emit('emmitPeople', this.store.data[index])
      this.$bvModal.hide('modal-people')
    }
  }
}
</script>

<style scoped>

</style>