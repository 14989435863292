<template>
  <div>
    <b-modal
        ref="modal-sessions"
        hide-footer
        id="modal-sessions"
        title="Sessão"
        size="lg"
    >
      <div class="d-block text-left">


        <div class="col-md-12">
          <div class="form-group">
            <label class="asterisco">Título da Sessão</label>
            <input class="form-control form-control-sm" type="text" v-model="description">
          </div>
        </div>


        <div class="col-md-12 mt-3" v-if="item.length > 0">
          <p>* Palestras em <span style="color: #FF0000">vermelho</span> estão cadastradas em outra sessão.</p>
          <table class="table table-striped" cellspacing="0" width="100%">
            <thead>
            <tr>
              <th></th>
              <th><i class="fa fa-chalkboard-teacher"></i> | Trilha</th>
              <th><i class="fa fa-clock"></i> | Início</th>
              <th><i class="fa fa-clock"></i> | Fim</th>
              <th><i class="fa fa-clock"></i> | Total Palestras</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="obj in item" :key="obj.trilha_id" :style="setColor(obj)">
              <td>
                <div class="custom-control custom-switch" style="">
                  <input type="checkbox"
                         class="custom-control-input switch-trilhas"
                         :id="`ss${obj.trilha_id}`"
                         @click="checkTrails(obj.trilha_id)"
                         :value="obj.trilha_id"
                  >
                  <label class="custom-control-label" :for="`ss${obj.trilha_id}`"></label>
                </div>
              </td>
              <td>{{ obj.trilha_nome }}</td>
              <td>{{ obj.data_inicio }}</td>
              <td>{{ obj.data_fim }}</td>
              <td>{{ obj.total_palestras }}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <button class="btn btn-outline-danger btn-lg mr-2" @click="cancel()">
            Cancelar
          </button>
          <!-- Button with custom close trigger value -->
          <button class="btn btn-tema-roxo text-white" @click="setSave">
            Salvar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalSessions",
  props: ['item', 'sessionId', 'title'],
  data: () => ({
    checked: [],
    description: null,
    open: false
  }),
  created() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'modal-sessions' && this.open === false){
        this.setValue()
        this.open = true
      }
    })

    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'modal-sessions' && this.open === true){
        this.open = false
      }
    })
  },
  methods: {

    setValue() {
      if (this.item) {
        this.description = this.title
        this.checked     = []

        for (var w = 0; w < this.item.length; w ++) {
          var current = this.item[w]
          var element = document.getElementById('ss'+current.trilha_id)

          if (current.sessao_id !== this.sessionId) {
            if (current.sessao_id !== null) {
              element.setAttribute('disabled', true)
            } else {
              element.removeAttribute('disabled')
            }
            element.removeAttribute('checked')
          } else {
            element.removeAttribute('disabled')
            element.setAttribute('checked', true)
            this.checked.push(current.trilha_id)
          }
        }

      }
    },

    setColor(trail) {
      if (trail.sessao_id !== null) {
        return trail.sessao_id !== this.sessionId ? 'color: rgb(255, 0, 0)' : ''
      } else {
        return ''
      }
    },

    checkTrails(id) {
      let element = document.getElementById('ss'+id)
      if (element.checked){
        this.checked.push(id)
      } else {
        this.checked.splice(this.checked.indexOf(id), 1)
      }
    },

    setFormatDate(dateTime) {
      var date = (dateTime.split(' '))[0].split('-')
      var time = (dateTime.split(' '))[1].split(':')

      return date[2] + '/' + date[1] + '/' + date[0] + ' ' + time[0] + ':' + time[1]
    },

    cancel() {
      this.$bvModal.hide('modal-sessions')
    },

    setSave() {
      let data = {
        id: this.sessionId,
        title: this.description,
        checked: this.checked
      }
      this.$emit('emmitSave', data)
    }
  }
}
</script>

<style scoped>

</style>