<template>
  <div class="card-info-palestras card-primary shadow-sm" id="card-info-palestras-28">
    <div class="row">
      <div class="col-md-12">
        <p class="excluir-info-palestra float-right text-danger" @click="setDelete">
          <i class="fal fa-trash-alt fa-lg"></i>
        </p>
      <p class="titulo-info-palestra text-primary " data-toggle="tooltip" data-placement="top" title="" data-original-title="Uma Visão Teológica do Discipulado">
        {{ item.titulo }}
      </p>
    </div>
      <div class="col-md-12 corpo-palestra">
        <p>
          <b>Início:</b> {{ setFormatDate(item.inicio) }}
        </p>
        <p style="margin-top: -10px;">
          <b>Fim:</b> {{ setFormatDate(item.fim) }}
        </p>
      </div>
      <div class="col-md-12">
        <button type="button" class="btn btn-outline-primary btn-block" @click="setEdit">
          <i class="fas fa-edit"></i> Editar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardLecture",
  props: ['item'],
  methods: {
    setEdit() {
      this.$emit('emmitEdit', this.item)
    },

    setDelete() {
      this.$emit('emmitDelete', this.item)
    },

    setFormatDate(dateTime) {
      var date = (dateTime.split(' '))[0].split('-')
      var time = (dateTime.split(' '))[1].split(':')

      return date[2] + '/' + date[1] + '/' + date[0] + ' ' + time[0] + ':' + time[1]
    }
  }
}
</script>

<style scoped>

</style>