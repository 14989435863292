<template>
  <div class="row text-left">
    <div class="col-md-12">
      <div class="card-conteudo">
        <div class="clearfix">
          <h4 class="float-left">SESSÕES</h4>
        </div>
        <br>

        <div class="row">

          <fieldset>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Criar sessão</label>
                  <input
                      class="form-control form-control-sm"
                      type="text"
                      v-model="form.title"
                      placeholder="Qual o nome da sessão?"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <button type="button" class="btn btn-primary mt-4" @click="setStore()"><i class="fal fa-plus"></i> Criar</button>
              </div>
            </div>
          </fieldset>
        </div>


        <div class="row boxTrilhas mt-4">
          <CardSessions
              v-for="item in store.sessions"
              :key="`cs${item.sessao_id}`"
              :item="item"
              v-on:emmitDelete="destroy"
              v-on:emmitEdit="editSession"
          ></CardSessions>
        </div>

        <ModalSessions
            :item="store.trails"
            :session-id="edit.id"
            :title="edit.title"
            v-on:emmitSave="setUpdate"
        ></ModalSessions>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import setNotification from "@/config/showNotifications";
import CardSessions from "@/components/events/CardSessions";
import ModalSessions from "@/components/events/ModalSessions";

export default {
  name: "TabSessions",
  components: {ModalSessions, CardSessions},
  props: ['eventId'],
  created() {
    this.getAllSessions()
  },
  data: () => ({
    store: {
      trails: [],
      sessions: []
    },
    form: {
      title: null
    },
    edit: {
      id: null,
      title: null
    }
  }),
  methods: {
    ...mapActions('lecture', ['getSessions', 'getTrails', 'setStoreSessions', 'setUpdateSessions', 'setDestroySessions']),

    async editSession(obj) {

      let data = await this.getTrails({id: this.eventId})

      if (data) {
        this.edit.id        = obj.sessao_id
        this.edit.title     = obj.sessao_descricao
        this.store.trails   = data
        this.$bvModal.show('modal-sessions')
      } else {
        setNotification(this, 'error', 'Tivemos um emprevisto, tente novamente')
      }
    },

    async getAllSessions() {
      let data = await this.getSessions({id: this.eventId})
      if (data) {
        this.store.sessions = data
      }
    },

    async setStore() {
      if (!this.form.title) {
        setNotification(this, 'error', 'Qual o nome da trilha?')
      } else {
        let data = this.setStoreSessions({title: this.form.title, event_id: this.eventId})
        if (data) {
          setNotification(this,'success', 'Sessão criada com sucesso')
          await this.getAllSessions()
          this.form.title = ''
        }
      }
    },

    async setUpdate(object) {
      object.eventId = this.eventId
      let data = await this.setUpdateSessions(object)
      if (data) {
        this.store.sessions = []
        await this.getAllSessions()
        this.$bvModal.hide('modal-sessions')
      }
    },

    async destroy(id) {

      this.$swal.fire({
        title: 'Tem certeza?',
        text: "Esta sessão será excluida",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!'
      }).then((result) => {
        if (result.isConfirmed) {
          let data = this.setDestroySessions({id: id})

          if (data) {
            this.$swal.fire(
                'Excluido!',
                'A sessão foi excluida',
                'success'
            )
            this.getAllSessions()
          } else {
            setNotification(this, 'error', 'Erro ao excluir trilha');
          }

        }
      })
    }
  }
}
</script>

<style scoped>

</style>