<template>
  <div>
    <b-modal
        ref="modal-trails"
        hide-footer
        id="modal-trails"
        title="Trilha"
        size="lg"
    >
      <div class="d-block text-left">


        <div class="col-md-12">
          <div class="form-group">
            <label class="asterisco">Título da Trilha</label>
            <input class="form-control form-control-sm" type="text" v-model="description">
          </div>
        </div>


        <div class="col-md-12 mt-3" v-if="item.length > 0">
          <p>* Palestras em <span style="color: #FF0000">vermelho</span> estão cadastradas em outra trilha.</p>
          <table class="table table-striped" cellspacing="0" width="100%">
            <thead>
            <tr>
              <th></th>
              <th><i class="fa fa-chalkboard-teacher"></i> | Palestra</th>
              <th><i class="fa fa-user"></i> | Palestrante</th>
              <th><i class="fa fa-clock"></i> | Início</th>
              <th><i class="fa fa-clock"></i> | Fim</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="obj in item" :key="obj.id" :style="setColor(obj.trilha)">
                <td>
                  <div class="custom-control custom-switch" style="">
                    <input type="checkbox"
                           class="custom-control-input switch-palestra"
                           :id="`st${obj.id}`"
                           @click="checkTrails(obj.id)"
                           :value="obj.id"
                    >
                    <label class="custom-control-label" :for="`st${obj.id}`"></label>
                  </div>
                </td>
                <td>{{ obj.titulo }}</td>
                <td>{{ obj.sou_palestrante !== null ? obj.sou_palestrante.pessoa.nome : '--' }}</td>
                <td>{{ setFormatDate(obj.inicio) }}</td>
                <td>{{ setFormatDate(obj.fim) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <button class="btn btn-outline-danger btn-lg mr-2" @click="cancel()">
            Cancelar
          </button>
          <!-- Button with custom close trigger value -->
          <button class="btn btn-tema-roxo text-white" @click="setSave">
            Salvar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalTrails",
  props: ['item', 'trailId', 'title'],
  data: () => ({
    checked: [],
    description: null,
    open: false
  }),
  created() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'modal-trails' && this.open === false){
        this.setValue()
        this.open = true
      }
    })

    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'modal-trails' && this.open === true){
        this.open = false
      }
    })
  },
  methods: {

    setValue() {
      if (this.item) {
        this.description = this.title
        this.checked     = []

        for (var w = 0; w < this.item.length; w ++) {
          var current = this.item[w]
          var element = document.getElementById('st'+current.id)

          if (current.trilha) {
            if (current.trilha.combo_id !== this.trailId) {
              element.setAttribute('disabled', true)
              element.removeAttribute('checked')
            } else {
              element.removeAttribute('disabled')
              element.setAttribute('checked', true)
              this.checked.push(current.id)
            }
          } else {
            element.removeAttribute('disabled')
          }
        }

      }
    },

    setColor(trail) {
      if (trail !== null) {
        return trail.combo_id !== this.trailId ? 'color: rgb(255, 0, 0)' : ''
      } else {
        return ''
      }
    },

    checkTrails(id) {
      let element = document.getElementById('st'+id)
      if (element.checked){
        this.checked.push(id)
      } else {
        this.checked.splice(this.checked.indexOf(id), 1)
      }
    },

    setFormatDate(dateTime) {
      var date = (dateTime.split(' '))[0].split('-')
      var time = (dateTime.split(' '))[1].split(':')

      return date[2] + '/' + date[1] + '/' + date[0] + ' ' + time[0] + ':' + time[1]
    },

    cancel() {
      this.$bvModal.hide('modal-trails')
    },

    setSave() {
      let data = {
        id: this.trailId,
        title: this.description,
        checked: this.checked
      }
      this.$emit('emmitSave', data)
    }
  }
}
</script>

<style scoped>

</style>