<template>
  <div class="row text-left">
    <div class="col-md-12">
      <div class="card-conteudo">
        <div class="clearfix">
          <h4 class="float-left">MODERADORES</h4>
        </div>
        <br>
        <div class="row">
          <div class="col-md-6" style="width: 1250px">
            <div class="form-group">
              <p>* Pessoas que podem moderar o evento</p>

              <div class="float-right mb-2">
                <b-input-group>
                  <b-input
                      class="form-control"
                      placeholder="Buscar..."
                      v-model="search"
                      v-on:keyup.enter="getSearch">
                  </b-input>
                  <b-input-group-append>
                    <b-button variant="outline-secondary" @click="getSearch"><i class="fa fa-search"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>

              <table class="table table-striped table-bordered nowrap" cellspacing="0" width="100%">
                <thead>
                  <tr role="row">
                    <th class="sorting_disabled" rowspan="1" colspan="1" style="width: 0px;">Nome</th>
                    <th class="sorting_disabled" rowspan="1" colspan="1" style="width: 0px;">Telefone</th>
                    <th class="sorting_disabled" rowspan="1" colspan="1" style="width: 0px;">Instituição</th>
                    <th class="sorting_disabled" rowspan="1" colspan="1" style="width: 0px;"></th>
                  </tr>
                </thead>
                <tbody>
                <tr role="row" class="odd" v-for="(obj, index) in moderators" :key="obj.id">
                  <td>
                    <img class="img-round-table" :src="getImg(obj.usuario ? obj.usuario.photo : null)">&nbsp;&nbsp;<span style="color:#0097FF">
                    {{obj.nome}}
                  </span>
                  </td>
                  <td>
                    <span style="color:#0097FF">{{obj.telefone}}</span>
                  </td>
                  <td>
                    <span style="color:#0097FF">{{obj.entidade ? obj.entidade.Acronym : '--'}}</span>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-outline-tema" disabled v-if="obj.pessoa_moderador">Escolhido</button>
                    <button class="btn btn-sm btn-outline-tema-roxo" v-else @click="setModerator(obj.id, index)">Escolher</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-1 p-3" v-if="totalPage > 1">
                <paginate
                    :pageCount="totalPage"
                    :clickHandler="setPage"
                    container-class="pagination justify-content-center"
                    page-class="page-item"
                    page-link-class="page-link"
                    prev-class="page-item"
                    prev-link-class="page-link"
                    next-class="page-item"
                    next-link-class="page-link"
                    prev-text="<<"
                    next-text=">>"
                >
                </paginate>

              </b-row>
          </div>

          <div class="col-md-6" id="mt-2" style="border-left: 1px solid #d9d9d9; padding-top: 10px">
            <h6 class="mt-45">Moderadores adicionados</h6>

            <div class="row">
              <div class="col-md-12 mt-1">
                <b-table
                    id="table-moderators-add"
                    :items="store.data"
                    :per-page="store.perPage"
                    :current-page="store.currentPage"
                    striped
                    :fields="store.fields"
                >

                  <template #cell(pessoa.nome)="data">
                    <img class="img-round-table" :src="getImg(data.item.pessoa.usuario ? data.item.pessoa.usuario.photo : null)">
                    &nbsp;&nbsp;<span>{{ data.item.pessoa.nome }}</span>
                  </template>

                  <template #cell(id)="data">
                    <button class="btn btn-outline-danger btn-sm" @click="setRemoveModerator(data.item.id, data.index)">Remover</button>
                  </template>

                </b-table>

                <b-pagination v-if="store.data.length > 10"
                    v-model="store.currentPage"
                    :total-rows="storeRows"
                    :per-page="store.perPage"
                    aria-controls="table-moderators-add"
                    first-number
                    last-number
                ></b-pagination>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Paginate from 'vuejs-paginate'
import setNotification from "@/config/showNotifications";

export default {
  name: "TabModerators",
  props: ['item'],
  created() {
    this.setValue()
  },
  data: () => ({
    event_id: null,
    search: null,
    totalPage: null,
    currentPage: null,
    myEntity: false,
    store: {
      fields: [
        {key: 'pessoa.nome', label: 'Nome'},
        {key: 'pessoa.telefone', label: 'Telefone'},
        {key: 'pessoa.entidade.Acronym', label: 'Instituição'},
        {key: 'id', label: ''}
      ],
      data: [],
      currentPage: 1,
      perPage: 10
    },
    moderators: [],
    path: 'https://sistemas.usb.org.br'
  }),
  computed: {
    storeRows() {
      return this.store.data.length
    }
  },
  methods: {
    ...mapActions('events', ['getModerators', 'setStoreModerator', 'setDestroyModerator']),

    async get() {
      let data = await this.getModerators(
          {
            id: this.event_id,
            search: this.search,
            page: this.currentPage,
            myEntity: this.myEntity
          })

      if (data) {
        this.moderators   = data.data
        this.totalPage    = data.lastPage
        this.currentPage  = data.page
      }

    },
    setValue() {
      let data = this.item
      if (data) {
        this.event_id     = data.id
        this.currentPage  = 1
        this.store.data   = data.moderadores
        this.get()
      }
    },

    getImg(img) {
      return img ? this.path + '/uploads/sistemasusb/usuarios/'+ img : this.path + '/uploads/sistemasusb/usuarios/avatar-default.png'
    },

    async setRemoveModerator(id, index) {
      let data = await this.setDestroyModerator({id: id})
      if (data) {
        this.store.data.splice(index, 1)
        await this.get()
        setNotification(this, 'success', 'Moderador removido')
      } else {
        setNotification(this, 'error', 'Um erro inesperado aconteceu')
      }
    },

    setPage(page) {
      this.currentPage = page
      this.get()
    },

    getSearch() {
      this.currentPage = 1
      this.totalPage   = 0
      this.get()
    },

    async setModerator(id, index) {
      let data = await this.setStoreModerator(
          {
            event_id: this.event_id,
            pessoa_id: id
          })
      if (data) {
        let add = {
          id: data.pessoa_moderador.id,
          pessoa: data
        }
        this.store.data.push(add)
        this.moderators[index].pessoa_moderador = true
        setNotification(this, 'success', 'Moderador adicionado')
      } else {
        setNotification(this, 'error', 'Um erro inesperado aconteceu')
      }
    }
  },
  components: {Paginate}
}
</script>

<style scoped>
  .btn.btn-outline-tema-roxo {
    color: #5d0479 !important;
  }
  .btn.btn-outline-tema-roxo:hover {
    color: #FAFAFA !important;
  }
  .mt-45{
    margin-top: 2.5rem;
  }
</style>