<template>
  <div class="row text-left">
    <div class="col-md-12">
      <div class="card-conteudo">
        <div class="clearfix">
          <h4 class="float-left">TRILHAS</h4>
        </div>
        <br>

        <div class="row">

          <fieldset>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Criar trilha</label>
                  <input
                      class="form-control form-control-sm"
                      type="text"
                      v-model="form.title"
                      placeholder="Qual o nome da trilha?"
                  >
                </div>
              </div>
              <div class="col-md-4">
                <button type="button" class="btn btn-primary mt-4" @click="setStore()"><i class="fal fa-plus"></i> Criar</button>
              </div>
            </div>
          </fieldset>
        </div>


        <div class="row boxTrilhas mt-4">
          <CardTrails
              v-for="item in store.trails"
              :key="item.trilha_id"
              :item="item"
              v-on:emmitDelete="destroy"
              v-on:emmitEdit="editTrail"
          ></CardTrails>
        </div>

        <ModalTrails
            :item="store.lectures"
            :trail-id="edit.id"
            :title="edit.title"
            v-on:emmitSave="setUpdate"
        ></ModalTrails>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import setNotification from "@/config/showNotifications";
import CardTrails from "@/components/events/CardTrails";
import ModalTrails from "@/components/events/ModalTrails";

export default {
  name: "TabTrails",
  components: {ModalTrails, CardTrails},
  props: ['eventId'],
  created() {
    this.getAllTrails()
  },
  data: () => ({
    store: {
      lectures: [],
      trails: []
    },
    form: {
      title: null
    },
    edit: {
      id: null,
      title: null
    }
  }),
  methods: {
    ...mapActions('lecture', ['getTrails', 'getLectures', 'setStoreTrails', 'setUpdateTrails', 'setDestroyTrails']),

    async editTrail(obj) {

      let data = await this.getLectures({id: this.eventId})
      if (data) {
        this.edit.id        = obj.trilha_id
        this.edit.title     = obj.trilha_nome
        this.store.lectures = data
        this.$bvModal.show('modal-trails')
      } else {
        setNotification(this, 'error', 'Tivemos um emprevisto, tente novamente')
      }
    },

    async getAllTrails() {
      let data = await this.getTrails({id: this.eventId})
      if (data) {
        this.store.trails = data
      }
    },

    async setStore() {
      if (!this.form.title) {
        setNotification(this, 'error', 'Qual o nome da trilha?')
      } else {
        let data = this.setStoreTrails({title: this.form.title, event_id: this.eventId})
        if (data) {
          setNotification(this,'success', 'Trilha criada com sucesso')
          await this.getAllTrails()
          this.form.title = ''
        }
      }
    },

    async setUpdate(object) {
      let data = await this.setUpdateTrails(object)
      if (data) {
        this.store.trails = []
        this.getAllTrails()
        this.$bvModal.hide('modal-trails')
      }
    },

    async destroy(id) {

      this.$swal.fire({
        title: 'Tem certeza?',
        text: "Esta trilha será excluida",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!'
      }).then((result) => {
        if (result.isConfirmed) {
          let data = this.setDestroyTrails({id: id})

          if (data) {
            this.$swal.fire(
                'Excluido!',
                'A trilha foi excluida',
                'success'
            )
            this.getAllTrails()
          } else {
            setNotification(this, 'error', 'Erro ao excluir trilha');
          }

        }
      })
    }
  }
}
</script>

<style scoped>

</style>