<template>
  <div>
    <b-modal
        id="modal-equipments"
        :title="titleModal"
        :hide-footer="true"
    >
      <div class="row" v-if="typeof item.sala_equipamentos != 'undefined'">
        <div class="col-md-12" v-if="item.sala_equipamentos.length > 0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Equipamento</th>
                <th>qtn</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="w in item.sala_equipamentos" :key="w.id">
                <td>{{ w.equipamentos.descricao }}</td>
                <td>{{ w.quantidade }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-md-12" v-else>
          <p class="text-danger">Nenhum equipamento nesta sala</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalEquipments",
  props: ['item', 'title'],
  computed: {
    titleModal() {
      return this.item.descricao + ' - Equipamentos'
    }
  }
}
</script>

<style scoped>

</style>