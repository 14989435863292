<template>
  <div class="row text-left">
    <div class="col-md-12">
      <div class="card-conteudo">
        <div class="clearfix">
          <h4 class="float-left">EVENTO | {{item.entidade.Acronym + ' ' + item.entidade.Name}}</h4>
        </div>
        <br>
        <div class="row">

          <ButtonEventCenter
              v-on:emmitEventCenter="setOptionEventCenter"
              :error="errorEventCenter"
              :description="item.centro_evento.descricao"
              :disabled="true"
          />

          <div class="col-md-12 mt-2">
            <b-form @submit.stop.prevent="onSubmit">
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col md="12">
                      <b-form-group id="form-group-title" label="Nome do evento" label-for="title">
                        <b-form-input
                            id="title"
                            name="title"
                            ref="title"
                            v-model="form.title"
                            v-validate="{required: true, min: 5}"
                            :state="validateState('title')"
                            aria-describedby="input-title-feedback"
                            data-vv-as="titulo"
                            placeholder="Digite.."
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-title-feedback">
                          {{ veeErrors.first('title') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group id="form-group-abstract" label="Resumo do Evento" label-for="abstract">
                        <b-form-textarea
                            id="abstract"
                            name="abstract"
                            ref="abstract"
                            v-model="form.abstract"
                            v-validate="{required: true, min: 20}"
                            :state="validateState('abstract')"
                            aria-describedby="input-abstract-feedback"
                            data-vv-as="resumo do evento"
                            placeholder="Digite.."
                            rows="3"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-abstract-feedback">
                          {{ veeErrors.first('abstract') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group id="form-group-info" label="Informações do evento" label-for="info">
                        <b-form-textarea
                            id="info"
                            name="info"
                            ref="info"
                            v-model="form.info"
                            v-validate="{required: false}"
                            :state="validateState('info')"
                            aria-describedby="input-info-feedback"
                            data-vv-as="informações do evento"
                            placeholder="Digite.."
                            rows="3"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-info-feedback">
                          {{ veeErrors.first('info') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="6">
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="7">
                          <b-form-group id="fieldset-dateStart"  label="Data inicial" label-for="dateStart">
                            <b-form-input
                                id="dateStart"
                                name="dateStart"
                                ref="dateStart"
                                v-validate="{required: true}"
                                :state="validateState('dateStart')"
                                data-vv-as="data inicial"
                                aria-describedby="input-dateStart-feedback"
                                v-model="form.dateStart"
                                type="date"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-dateStart-feedback">
                              {{ veeErrors.first('dateStart') }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col md="5">
                          <b-form-group id="fieldset-timeStart" label="Hora inicial" label-for="timeStart">
                            <b-form-input
                                id="timeStart"
                                name="timeStart"
                                ref="timeStart"
                                v-validate="{required: true}"
                                :state="validateState('timeStart')"
                                data-vv-as="hora inicial"
                                aria-describedby="input-timeStart-feedback"
                                v-model="form.timeStart"
                                type="time"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-timeStart-feedback">
                              {{ veeErrors.first('dateEnd') }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="7">
                          <b-form-group id="fieldset-dateEnd"  label="Data final" label-for="dateEnd">
                            <b-form-input
                                id="dateEnd"
                                name="dateEnd"
                                ref="dateEnd"
                                v-validate="{required: true}"
                                :state="validateState('dateEnd')"
                                data-vv-as="data final"
                                aria-describedby="input-dateEnd-feedback"
                                v-model="form.dateEnd"
                                type="date"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-dateEnd-feedback">
                              {{ veeErrors.first('dateEnd') }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col md="5">
                          <b-form-group id="fieldset-timeEnd" label="Hora final" label-for="timeEnd">
                            <b-form-input
                                id="timeEnd"
                                name="timeEnd"
                                ref="timeEnd"
                                v-validate="{required: true}"
                                :state="validateState('timeEnd')"
                                data-vv-as="hora final"
                                aria-describedby="input-timeEnd-feedback"
                                v-model="form.timeEnd"
                                type="time"
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-timeEnd-feedback">
                              {{ veeErrors.first('timeEnd') }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-md-12" style="height: 277px;" @click="setFile">
                      <form id="form-image" action="" method="post" name="form-image" enctype="multipart/form-data">
                        <label for="file">Foto do evento</label>
                        <div class="input-group form-group" hidden>
                          <div class="custom-file">
                            <input
                                type="file"
                                class="custom-file-input input-image"
                                name="file"
                                id="file"
                                ref="file"
                                accept="image/png, image/jpeg"
                                @change="changeImage"
                            >
                            <label class="custom-file-label fotoEvento" for="event-image">Selecionar foto</label>
                          </div>
                        </div>
                        <div class="image-holder" id="event-image" :style='`background-image: url(${imgEvent})`'></div>
                      </form>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-button variant="tema-roxo text-white" size="lg" @click="onSubmit">Salvar cadastro</b-button>
                </b-col>
              </b-row>
            </b-form>

          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import {mapActions} from 'vuex'
import ButtonEventCenter from "@/components/eventCenter/ButtonEventCenter";
import setNotification from '@/config/showNotifications'

export default {
  name: "TabBasicRegistration",
  props: ['item'],
  created() {
    this.setValues()
  },
  update() {
    this.setValues()
  },
  computed: {
    imgEvent() {
      return this.image.current ? this.image.current : 'https://sistemas.usb.org.br/aes-new/images/upload-image.png'
    }
  },
  data: () => ({
    form: {
      name: null,
      title: null,
      dateStart: null,
      dateEnd: null,
      timeStart: null,
      timeEnd: null,
      eventCenterId: null,
      info: null,
      abstract: null,
      id: null,
      photo: ''
    },
    image: {
      current: '',
      isUpload: false,
      newName: '',
      newImg: '../../images/upload-image.png'
    },
    nameCenterEvent: null,
    errorEventCenter: false,
    file: null,
    path: process.env.NODE_ENV === 'production' ? 'https://sistemas.usb.org.br' : 'http://test-sistemas.usb.org.br'
  }),
  methods: {
    ...mapActions('events', ['setUpdate', 'setUpload', 'setDestroyUpload']),

    setOptionEventCenter(object) {
      this.form.eventCenterId = object.id
      this.errorEventCenter = false
    },

    validateState(ref) {
      if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    onSubmit() {
      this.$validator.validateAll().then(result => {

        var isValid = true

        if (!this.form.eventCenterId) {
          this.errorEventCenter = true
          isValid = false
        } else {
          this.errorEventCenter = false
        }

        if (!result) {
          isValid = false
        }

        if (!isValid) {
          return
        }

        this.setSaveEvent()
      });
    },

    async setSaveEvent() {

      if (this.image.isUpload) {

        if (this.form.photo !== null) {
          let img = this.form.photo.split('/uploads/aes/evento/')[1]
          await this.setDestroyUpload({image: img, path: '/aes/evento/'})
        }

        var form = new FormData()
        form.append('imagem', this.file)
        form.append('path', '/aes/evento/')

        let upload = await this.setUpload(form)

        if (upload) {
          this.form.photo = '/uploads/aes/evento/'+ upload
          this.image.isUpload = false
        }
      }

      let data = await this.setUpdate(this.form)
      if (data) {
          setNotification(this, 'success', 'Informações salvas')
      } else {
        setNotification(this, 'error', 'Um erro inesperado aconteceu')
      }
    },

    setValues() {
      let data = this.item
      if (data) {
        this.form.title = data.nome
        this.form.dateStart = (data.inicio).split(' ')[0]
        this.form.timeStart = (data.inicio).split(' ')[1]
        this.form.dateEnd = (data.fim).split(' ')[0]
        this.form.timeEnd = (data.fim).split(' ')[1]
        this.form.info = data.informacao
        this.form.abstract = data.descricao
        this.form.eventCenterId = data.centro_evento_id
        this.form.id = data.id

        if (data.foto) {
          this.form.photo    = data.foto
          this.image.current = this.path+data.foto
          this.setChangeImg(false, null, false)
        } else {
          this.setChangeImg(false, null, true)
        }
      }
    },

    setFile() {
      document.getElementById('file').click()
    },

    changeImage() {
      let files = document.getElementById('file').files

      if (!files.length || !window.FileReader) return;

      if (/^image/.test( files[0].type)){

        var reader = new FileReader();
        reader.readAsDataURL(files[0]);

        var length = files[0].size / 1000;

        if ( length < 11000) {

          this.file = this.$refs.file.files[0];
          reader.onload = function(){
            document.getElementById('event-image').style.backgroundImage = 'url(' + this.result + ')'
          }
          var date = new Date()
          this.setChangeImg(true, 'AA_' + date.getMilliseconds()+date.getSeconds() + this.form.id, false)
        } else {
          this.image.upload = false
          this.setChangeImg(false, null, true)
          setNotification(this, 'error', 'Selecione uma imagem menor')
        }

      } else {
        setNotification(this, 'error', 'Você tem que escolher uma imagem do tipo PNG, JPG ou JPEG')
        this.setChangeImg(false, null, true)
      }
    },

    setChangeImg(upload, name, isDefault) {
      this.image.isUpload = upload
      this.image.newName  = name

      if (isDefault) {
        this.image.current = ''
      }
    }
  },
  components: {
    ButtonEventCenter
  }
}
</script>

<style scoped>

</style>