<template>
  <div id="details">
    <SecondaryMenu title="Eventos | Detalhes Evento"/>

    <b-container fluid="" v-if="object">
      <b-row>
        <b-col md="12" class="pl-5 pr-5">
          <b-tabs content-class="mt-3" justified v-model="tabIndex">
            <b-tab title="BÁSICO" active>
              <TabBasicRegistration :item="object"/>
            </b-tab>
            <b-tab title="CONFIGURAÇÕES">
              <TabConfiguration :item="object"/>
            </b-tab>
            <b-tab title="FUNÇÃO">
              <TabFunctions :item="object"/>
            </b-tab>
            <b-tab title="INSCRIÇÕES">
              <TabRegistrations :item="object" />
            </b-tab>
            <b-tab title="MODERADORES">
              <TabModerators :item="object" />
            </b-tab>
            <b-tab title="PALESTRAS">
              <TabLecture :item="object"/>
            </b-tab>
            <b-tab title="TRILHAS" >
              <TabTrails :event-id="object.id"></TabTrails>
            </b-tab>
            <b-tab title="SESSÕES">
              <TabSessions :event-id="object.id"></TabSessions>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else>
      <b-row>
        <b-col md="12">
          <b-skeleton animation="wave" width="100%" height="200px"></b-skeleton>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import SecondaryMenu from "@/components/layout/SecondaryMenu";
import TabBasicRegistration from "@/components/events/TabBasicRegistration";
import TabConfiguration from "@/components/events/TabConfiguration";
import TabFunctions from "@/components/events/TabFunctions";
import TabModerators from "@/components/events/TabModerators";
import TabRegistrations from "@/components/events/TabRegistrations";
import TabLecture from "@/components/events/TabLecture";
import TabTrails from "@/components/events/TabTrails";
import TabSessions from "@/components/events/TabSessions";

export default {
  name: "DetailsEvent",
  components: {
    TabSessions,
    TabTrails,
    TabLecture,
    TabRegistrations,
    TabModerators,
    TabFunctions,
    TabConfiguration,
    TabBasicRegistration,
    SecondaryMenu
  },
  data: () => ({
    object: null,
    tabIndex: 1
  }),
  created() {
    this.get()
  },
  methods: {
    ...mapActions('events', ['getDetailsEvent']),

    async get() {
      let data = await this.getDetailsEvent({id: this.$route.params.event})

      if (data) {
        this.object = data
      }
    }
  }
}
</script>

<style scoped>

</style>