<template>
  <div>
    <b-modal
        id="modal-lecture"
        title="Cadastro de Palestra"
        size="lg"
        :hide-footer="true"
    >
      <b-form @submit.stop.prevent="onSubmit" class="p-3 mb-3">

        <b-row>
          <b-col md="12">
            <b-form-group id="form-group-title" label="Titulo da palestra" label-for="title">
              <b-form-input
                  id="title"
                  name="title"
                  ref="title"
                  v-model="form.title"
                  v-validate="{required: true, min: 5}"
                  :state="validateState('title')"
                  aria-describedby="input-title-feedback"
                  data-vv-as="titulo"
                  placeholder="Digite.."
              ></b-form-input>
              <b-form-invalid-feedback id="input-title-feedback">
                {{ veeErrors.first('title') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="7">
                <b-form-group id="fieldset-dateStart"  label="Data inicial" label-for="dateStart">
                  <b-form-input
                      id="dateStart"
                      name="dateStart"
                      ref="dateStart"
                      v-validate="{required: true}"
                      :state="validateState('dateStart')"
                      data-vv-as="data inicial"
                      aria-describedby="input-dateStart-feedback"
                      v-model="form.dateStart"
                      type="date"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-dateStart-feedback">
                    {{ veeErrors.first('dateStart') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group id="fieldset-timeStart" label="Hora inicial" label-for="timeStart">
                  <b-form-input
                      id="timeStart"
                      name="timeStart"
                      ref="timeStart"
                      v-validate="{required: true}"
                      :state="validateState('timeStart')"
                      data-vv-as="hora inicial"
                      aria-describedby="input-timeStart-feedback"
                      v-model="form.timeStart"
                      type="time"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-timeStart-feedback">
                    {{ veeErrors.first('dateEnd') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="7">
                <b-form-group id="fieldset-dateEnd"  label="Data final" label-for="dateEnd">
                  <b-form-input
                      id="dateEnd"
                      name="dateEnd"
                      ref="dateEnd"
                      v-validate="{required: true}"
                      :state="validateState('dateEnd')"
                      data-vv-as="data final"
                      aria-describedby="input-dateEnd-feedback"
                      v-model="form.dateEnd"
                      type="date"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-dateEnd-feedback">
                    {{ veeErrors.first('dateEnd') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group id="fieldset-endTime" label="Hora final" label-for="endTime">
                  <b-form-input
                      id="endTime"
                      name="endTime"
                      ref="endTime"
                      v-validate="{required: true}"
                      :state="validateState('endTime')"
                      data-vv-as="hora final"
                      aria-describedby="input-endTime-feedback"
                      v-model="form.endTime"
                      type="time"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-endTime-feedback">
                    {{ veeErrors.first('endTime') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col md="12">
            <b-form-group id="form-group-abstract" label="Resumo da palestra" label-for="abstract">
              <b-form-textarea
                  id="abstract"
                  name="abstract"
                  ref="abstract"
                  v-model="form.abstract"
                  v-validate="{required: true, min: 20}"
                  :state="validateState('abstract')"
                  aria-describedby="input-abstract-feedback"
                  data-vv-as="resumo da palestra"
                  placeholder="Digite.."
                  rows="2"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-abstract-feedback">
                {{ veeErrors.first('abstract') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-form-group id="form-group-link" label="Link do vídeo" label-for="link">
              <b-form-input
                  id="link"
                  name="link"
                  ref="link"
                  v-model="form.link"
                  v-validate="{required: false}"
                  :state="validateState('link')"
                  aria-describedby="input-link-feedback"
                  data-vv-as="link"
                  placeholder="Digite.."
              ></b-form-input>
              <b-form-invalid-feedback id="input-link-feedback">
                {{ veeErrors.first('link') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <label>Tipo de Palestra</label>
            <b-form-select
                :options="options"
                v-model="form.type"
                name="type"
                :state="validateState('type')"
                v-validate="{required: true}"
                aria-describedby="input-type-feedback"
                data-vv-as="tipo de palestra"
                @change="validWorkshop"
            ></b-form-select>
          </b-col>
          <b-col md="8">
            <b-row>
              <b-col md="4">
                <b-form-checkbox
                    id="workshop"
                    v-model="form.workshop"
                    name="workshop"
                    class="mt-4"
                    :disabled="form.type!==2"
                >
                  Workshop
                </b-form-checkbox>
              </b-col>
              <b-col md="8">
                <b-form-group id="form-group-limit" label="Limite de vagas para o workshop" label-for="limit">
                  <b-form-input
                      id="limit"
                      name="limit"
                      ref="limit"
                      v-model="form.limit"
                      v-validate="{required: false}"
                      :state="validateState('limit')"
                      aria-describedby="input-limit-feedback"
                      data-vv-as="limite de vagas"
                      placeholder="Digite.."
                      :disabled="!form.workshop"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-limit-feedback">
                    {{ veeErrors.first('limit') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <div class="col-md-12">
            <div class="card">
            <div class="card-body">
              <b-row>
                <b-col md="12" class="mt-3 mb-1">
                  <h4>
                    Palestrantes
                    <button type="button"
                            class="btn btn-primary btn-sm float-right"
                            @click="openModalPeople"
                    >+ adicionar</button>
                  </h4>

                </b-col>

                <b-col md="12" v-if="speakers.data.length > 0">
                  <b-table
                      id="my-functions-add"
                      :items="speakers.data"
                      striped
                      :fields="speakers.fields"
                      size="sm"
                  >
                    <template #cell(id)="data">
                      <button class="btn btn-sm btn-outline-danger mr-2 float-right"
                              @click="setRemove(data.index, data.item.id)"
                              type="button"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </template>
                  </b-table>
                </b-col>

                <b-col md="12" v-else>
                  <b-alert variant="info" :show="true">
                    Nenhum palestrante adicionado
                  </b-alert>
                </b-col>
              </b-row>

            </div>
          </div>
          </div>
        </b-row>

        <div class="row">
          <div class="col-md-12" style="height: 277px; padding-top: 5px" @click="setFile">
            <form id="form-image" action="" method="post" name="form-image" enctype="multipart/form-data">
              <label for="file_lecture">Foto da palestra</label>
              <div class="input-group form-group" hidden>
                <div class="custom-file">
                  <input
                      type="file"
                      class="custom-file-input input-image"
                      name="file_lecture"
                      id="file_lecture"
                      ref="file_lecture"
                      accept="image/png, image/jpeg"
                      @change="changeImage"
                  >
                  <label class="custom-file-label fotoEvento" for="lecture-image">Selecionar foto</label>
                </div>
              </div>
              <div class="image-holder" id="lecture-image" :style='`background-image: url(${imgLecture})`'></div>
            </form>
          </div>
        </div>
      </b-form>

      <b-row>
        <b-col md="12" class="text-right">
          <b-button size="lg" variant="outline-danger mr-2" @click="cancel()">
            Cancelar
          </b-button>
          <!-- Button with custom close trigger value -->
          <b-button size="lg" variant="tema-roxo text-white" @click="onSubmit">
            Salvar
          </b-button>
        </b-col>
      </b-row>

    </b-modal>

    <ModalPeople v-on:emmitPeople="setPeople"/>
  </div>
</template>

<script>

import ModalPeople from "@/components/events/ModalPeople";
import setNotification from "@/config/showNotifications";
import {mapActions} from "vuex";
export default {
  name: "ModalLecture",
  components: {ModalPeople},
  props: ['item'],
  computed: {
    imgLecture() {
      return this.image.current ? this.image.current : 'https://sistemas.usb.org.br/aes-new/images/upload-image.png'
    }
  },
  data: () => ({
    form: {
      id: null,
      name: null,
      title: null,
      dateStart: null,
      dateEnd: null,
      timeStart: null,
      endTime: null,
      abstract: null,
      type: null,
      link: null,
      workshop: null,
      limit: null,
      peoples: [],
      photo: null
    },
    options: [
      {value: 1, text: 'Abertura'},
      {value: 2, text: 'Palestra'},
      {value: 3, text: 'Intervalo'},
      {value: 4, text: 'Encerramento'}
    ],
    speakers: {
      fields: [
        {key: 'pessoa.nome', label: ''},
        {key: 'id', label: ''}
      ],
      data: []
    },
    image: {
      current: '',
      isUpload: false,
      newName: '',
      newImg: '../../images/upload-image.png'
    },
    file: null,
    path: process.env.NODE_ENV === 'production' ? 'https://sistemas.usb.org.br' : 'http://test-sistemas.usb.org.br'
  }),
  created() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'modal-lecture'){
        this.setValue()
      }
    })
  },
  methods: {
    ...mapActions('events', ['setUpload', 'setDestroyUpload']),

    setValue() {
      let data = this.item

      if (data) {
        this.form.title     = data.titulo
        this.form.dateStart = (data.inicio).split(' ')[0]
        this.form.timeStart = (data.inicio).split(' ')[1]
        this.form.dateEnd   = (data.fim).split(' ')[0]
        this.form.endTime   = (data.fim).split(' ')[1]
        this.form.abstract  = data.resumo
        this.form.id        = data.id
        this.form.type      = data.tipo_palestra_id
        this.form.link      = data.video
        this.form.workshop  = data.workshop
        this.form.limit     = data.limite_vaga
        this.speakers.data  = data.palestrantes
        this.form.peoples   = []

        if (data.foto) {
          this.form.photo    = data.foto
          this.image.current = this.path+data.foto
          this.setChangeImg(false, null, false)
        } else {
          this.setChangeImg(false, null, true)
        }

        if (typeof data.palestrantes !== "undefined") {
          for (var w = 0; w < data.palestrantes.length; w ++) {
            (this.form.peoples).push(data.palestrantes[w].pessoa_id)
          }
        }
      } else {
        this.setClear()
      }
    },

    setClear() {
      this.form.title     = null
      this.form.dateStart = null
      this.form.timeStart = null
      this.form.dateEnd   = null
      this.form.endTime   = null
      this.form.abstract  = null
      this.form.id        = null
      this.form.type      = null
      this.form.link      = null
      this.form.workshop  = null
      this.form.limit     = null
      this.form.peoples   = []
      this.speakers.data  = []
    },

    cancel() {
      this.$bvModal.hide('modal-lecture')
    },

    validWorkshop() {
      if (this.form.type !== 2) {
        this.form.workshop = false
        this.form.limit    = null
      }
    },

    validateState(ref) {
      if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    onSubmit() {
      this.$validator.validateAll().then(result => {

        var isValid = true

        if (!result) {
          isValid = false
        }

        if (!isValid) {
          return
        }

        if (this.image.isUpload) {
          this.upload()
        } else {
          this.$emit('emmitStore', this.form)
        }
      });
    },

    openModalPeople() {
      this.$bvModal.show('modal-people')
    },

    setRemove(index, id) {
      this.speakers.data.splice(index, 1)
      for (var w = 0; w < this.form.peoples.length; w ++) {
        if (this.form.peoples[w] === id) {
          index = w
        }
      }
      this.form.peoples.splice(index, 1)
    },

    setPeople(obj) {
      var index = null

      for (var w = 0; w < this.form.peoples.length; w ++) {
        if (this.form.peoples[w] === obj.id) {
          index = w
        }
      }

      if (index === null) {
        this.form.peoples.push(obj.id)
        this.speakers.data.push({pessoa: {nome: obj.nome}, id: obj.id})
      }
    },

    setFile() {
      document.getElementById('file_lecture').click()
    },

    changeImage() {
      let files = document.getElementById('file_lecture').files

      if (!files.length || !window.FileReader) return;

      if (/^image/.test( files[0].type)){

        var reader = new FileReader();
        reader.readAsDataURL(files[0]);

        var length = files[0].size / 1000;

        if ( length < 11000) {

          this.file = this.$refs.file_lecture.files[0];
          reader.onload = function(){
            document.getElementById('lecture-image').style.backgroundImage = 'url(' + this.result + ')'
          }
          var date = new Date()
          this.setChangeImg(true, 'AA_' + date.getMilliseconds()+date.getSeconds() + this.form.id, false)
        } else {
          this.image.upload = false
          this.setChangeImg(false, null, true)
          setNotification(this, 'error', 'Selecione uma imagem menor')
        }

      } else {
        setNotification(this, 'error', 'Você tem que escolher uma imagem do tipo PNG, JPG ou JPEG')
        this.setChangeImg(false, null, true)
      }
    },

    setChangeImg(upload, name, isDefault) {
      this.image.isUpload = upload
      this.image.newName  = name

      if (isDefault) {
        this.image.current = ''
      }
    },

    async upload() {

      if (this.form.photo !== null) {
        let img = this.form.photo.split('/uploads/aes/evento/')[1]
        await this.setDestroyUpload({image: img, path: '/aes/evento/'})
      }

      var form = new FormData()
      form.append('imagem', this.file)
      form.append('path', '/aes/evento/')

      let upload = await this.setUpload(form)

      if (upload) {
        this.form.photo = '/uploads/aes/evento/'+ upload
        this.image.isUpload = false
      }

      this.$emit('emmitStore', this.form)
    }
  }

}
</script>

<style scoped>

</style>