<template>
  <div class="row text-left">
    <div class="col-md-12">
      <div class="clearfix">
        <h4 class="float-left">INSCRIÇÃO NO EVENTO - INSTITUIÇÕES</h4>
      </div>
      <br>
      <div class="row">
        <div class="col-md-6" style="width: 1250px">
          <div class="form-group">
            <p>* Instituições que podem se inscrever no evento</p>

            <div class="float-right mb-2">
              <b-input-group>
                <b-input
                    class="form-control"
                    placeholder="Buscar..."
                    v-model="search"
                    v-on:keyup.enter="getSearch">
                </b-input>
                <b-input-group-append>
                  <b-button variant="outline-secondary" @click="getSearch"><i class="fa fa-search"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>

            <table class="table table-striped table-bordered nowrap" cellspacing="0" width="100%">
              <thead>
              <tr role="row">
                <th class="sorting_disabled" rowspan="1" colspan="1">Instituição</th>
                <th class="sorting_disabled" rowspan="1" colspan="1"></th>
              </tr>
              </thead>
              <tbody>
              <tr role="row" class="odd" v-for="(obj, index) in entities" :key="obj.Entityid">
                <td>
                  {{ obj.name }}
                </td>
                <td>
                  <button class="btn btn-sm btn-outline-tema" disabled v-if="obj.evento_id">Escolhido</button>
                  <button class="btn btn-sm btn-outline-tema-roxo" v-else @click="setEntity(obj.Entityid, index)">Escolher</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <b-row class="mt-1 p-3" v-if="totalPage > 1">
            <paginate
                :pageCount="totalPage"
                :clickHandler="setPage"
                container-class="pagination justify-content-center"
                page-class="page-item"
                page-link-class="page-link"
                prev-class="page-item"
                prev-link-class="page-link"
                next-class="page-item"
                next-link-class="page-link"
                prev-text="<<"
                next-text=">>"
            >
            </paginate>

          </b-row>
        </div>

        <div class="col-md-6" id="mt-2" style="border-left: 1px solid #d9d9d9; padding-top: 10px">
          <h6 class="mt-45">Instituições adicionadas</h6>

          <div class="row">
            <div class="col-md-12 mt-1">

              <table class="table table-striped">
                <tbody>
                <tr v-for="(entity, index) in store" :key="index">

                  <div v-if="entity.entidade">
                    <td style="width: 45%">
                      <b>Instituição: </b>{{entity.entidade.Acronym + ' - ' + entity.entidade.Name }}
                    </td>
                    <td style="width: 10%">
                      <button class="btn btn-outline-danger btn-sm" @click="setRemoveEntity(entity.id, index)">Remover</button>
                    </td>
                  </div>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Paginate from 'vuejs-paginate'
import setNotification from "@/config/showNotifications";

export default {
  name: "RegistrationEntity",
  props: ['item'],
  created() {
    this.setValue()
  },
  data: () => ({
    event_id: null,
    search: null,
    totalPage: null,
    currentPage: null,
    store: [],
    entities: []
  }),
  methods: {
    ...mapActions('events', ['getRegisterEntities', 'setStoreRegister', 'setDestroyRegister']),

    async get() {
      let data = await this.getRegisterEntities(
          {
            id: this.event_id,
            search: this.search,
            page: this.currentPage
          })

      if (data) {
        this.entities     = data.data
        this.totalPage    = data.lastPage
        this.currentPage  = data.page
      }

    },
    setValue() {
      let data = this.item
      if (data) {
        this.event_id     = data.id
        this.currentPage  = 1
        this.store        = data.inscreve_evento
        this.get()
      }
    },

    async setRemoveEntity(id, index) {
      let data = await this.setDestroyRegister({id: id})
      if (data) {
        this.store.splice(index, 1)
        await this.get()
        setNotification(this, 'success', 'Instituição removida')
      } else {
        setNotification(this, 'error', 'Um erro inesperado aconteceu')
      }
    },

    setPage(page) {
      this.currentPage = page
      this.get()
    },

    getSearch() {
      this.currentPage = 1
      this.totalPage   = 0
      this.get()
    },

    async setEntity(id, index) {
      let data = await this.setStoreRegister(
          {
            event_id: this.event_id,
            entity_id: id,
            people_id: null
          })
      if (data) {
        this.store.push(data)
        this.entities[index].entidade_inscreve_evento = true
        setNotification(this, 'success', 'Instituição adicionada')
      } else {
        setNotification(this, 'error', 'Um erro inesperado aconteceu')
      }
    }
  },
  components: {Paginate}
}
</script>

<style scoped>
.btn.btn-outline-tema-roxo {
  color: #5d0479 !important;
}
.btn.btn-outline-tema-roxo:hover {
  color: #FAFAFA !important;
}
.mt-45{
  margin-top: 2.5rem;
}
</style>