<template>
  <div class="card-info-palestras card-primary shadow-sm">
    <div class="row">
      <div class="col-md-12">
        <p class="excluir-info-palestra float-right text-danger" @click="setDelete"><i class="fal fa-trash-alt fa-lg"></i></p>
        <p class="titulo-info-palestra text-primary">{{ item.trilha_nome }}</p>
      </div>
      <div class="col-md-12 corpo-palestra">
        <p><b>Início:</b> {{ item.data_inicio }}</p>
        <p style="margin-top: -10px;"><b>Fim:</b> {{ item.data_fim }}</p>
        <p style="margin-top: -5px;"><b>Palestras: </b> {{ item.total_palestras }}</p>
      </div>
      <div class="col-md-12">
        <button type="button" class="btn btn-outline-success btn-block" @click="setEdit"><i class="fas fa-edit"></i> Editar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTrails",
  props: ['item'],
  methods: {
    setDelete() {
        this.$emit('emmitDelete', this.item.trilha_id)
    },

    setEdit() {
      this.$emit('emmitEdit', this.item)
    }
  }
}
</script>

<style scoped>

</style>