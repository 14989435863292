<template>
  <div class="row text-left">
    <div class="col-md-12">
      <div class="card-conteudo">
        <div class="clearfix">
          <h4 class="float-left">CONFIGURAÇÕES</h4>
        </div>
        <br>
        <div class="row">
          <b-col md="12" class="mb-3">
            <b-form-checkbox v-model="form.aceita_inscricao_app" name="check-button_inscricao_app" switch>
              Aceita inscrição pelo app? <b>(Atual: {{ getDescriptionCheck(form.aceita_inscricao_app) }})</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="12" class="mb-3" v-if="form.aceita_inscricao_app">
            <b-form-checkbox v-model="form.inscricao_app_com_codigo" name="check-button_inscricao_app_codigo" switch>
              Aceita inscrição pelo app com código? <b>(Atual: {{ getDescriptionCheck(form.inscricao_app_com_codigo) }})</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="12" class="mb-3" v-if="form.inscricao_app_com_codigo">
            <b-input-group size="sm" prepend="CÓDIGO">
              <b-form-input v-model="form.codigo"></b-form-input>
            </b-input-group>
          </b-col>

          <b-col md="12" class="mb-3">
            <b-form-checkbox v-model="form.evento_sem_palestra" name="check-button_evento_sem_palestra" switch>
              Evento sem palestras <b>(Atual: {{ getDescriptionCheck(form.evento_sem_palestra) }})</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="12" class="mb-3">
            <b-form-checkbox v-model="form.mostra_perguntas" name="check-button_mostrar_perguntas_app" switch>
              Mostrar perguntas no app <b>(Atual: {{ getDescriptionCheck(form.mostra_perguntas) }})</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="12" class="mb-3">
            <b-form-checkbox v-model="form.varias_leituras" name="check-button_varias_leituras_presenca" switch>
              Permitir várias leituras de presença no evento <b>(Atual: {{ getDescriptionCheck(form.varias_leituras) }})</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="12" class="mb-3">
            <b-form-checkbox v-model="form.precisa_transporte" name="check-button_precisa_transporte" switch>
              Evento precisa de transporte <b>(Atual: {{ getDescriptionCheck(form.precisa_transporte) }})</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="12" class="mb-3">
            <b-form-checkbox v-model="form.precisa_hotel" name="check-button_precisa_hotel" switch>
              Evento precisa de hotel <b>(Atual: {{ getDescriptionCheck(form.precisa_hotel) }})</b>
            </b-form-checkbox>
          </b-col>
          <hr>
          <b-col md="12" class="mt-4">
            <b-button variant="tema-roxo text-white" size="lg" @click="setSave">Salvar configurações</b-button>
          </b-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import setNotification from "@/config/showNotifications";


export default {
  name: "TabConfiguration",
  props: ['item'],
  created() {
    this.setValues()
  },
  data: () => ({
    form: {
      evento_id: null,
      aceita_inscricao_app: false,
      inscricao_app_com_codigo: false,
      codigo: false,
      inscricao_fechada: false,
      evento_sem_palestra: false,
      mostra_perguntas: false,
      varias_leituras: false,
      precisa_transporte: false,
      precisa_hotel: false,
    }
  }),
  methods: {
    ...mapActions('events', ['setUpdateConfig']),

    setValues() {
      let data = this.item

      if (data) {
        this.form.evento_id                 = data.id
        this.form.aceita_inscricao_app      = !!data.aceita_inscricao_app
        this.form.inscricao_app_com_codigo  = !!data.inscricao_app_com_codigo
        this.form.codigo                    = data.codigo
        this.form.inscricao_fechada         = !!data.inscricao_fechada
        this.form.evento_sem_palestra       = !!data.evento_sem_palestra
        this.form.mostra_perguntas          = !!data.mostra_perguntas
        this.form.varias_leituras           = !!data.varias_leituras
        this.form.precisa_transporte        = !!data.precisa_transporte
        this.form.precisa_hotel             = !!data.precisa_hotel
      }
    },
    getDescriptionCheck(value) {
      return value ? 'SIM' : 'NÃO'
    },

    async setSave(){
      let data = await this.setUpdateConfig({form: this.form})

      if (data) {
        setNotification(this, 'success', 'Configurações salvas')
      } else {
        setNotification(this, 'error', 'Um erro inesperado aconteceu')
      }
    }
  }
}
</script>

<style scoped>

</style>