<template>
  <div class="row">
    <div class="col-md-12">
      <b-card no-body>
        <b-tabs pills card>
          <b-tab title="Pessoas" active>
            <RegistrationPeople :item="item" />
          </b-tab>
          <b-tab title="Instituições">
            <RegistrationEntity :item="item" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>

import RegistrationPeople from "@/components/events/RegistrationPeople";
import RegistrationEntity from "@/components/events/RegistrationEntity";

export default {
  name: "TabRegistrations",
  components: {RegistrationEntity, RegistrationPeople},
  props: ['item']
}
</script>

<style scoped>

</style>