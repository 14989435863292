<template>
  <div :class="`card-info-sala ${isSelected}`">
    <div class="card-info-sala-image"
         :style="`background-image: url(${getBasePhoto + item.foto})`"
         @click="setSelected">
      <div class="custom-control custom-checkbox">
        <input
            type="checkbox"
            class="custom-control-input check-sala"
            :id="item.id"
            :key="item.id"
            :checked="isChecked"
        >
        <label class="custom-control-label" :for="item.id"></label>
      </div>
    </div>
    <div class="card-info-sala-texto">
      <div class="card-info-sala-titulo">
        {{ item.descricao }}
      </div>
      <div class="card-info-sala-conteudo">
        <div class="row">
          <div class="col-auto">
            <div class="card-info-sala-capacidade">
              <i class="fas fa-users"></i> {{ item.capacidade }}<br>
            </div>
          </div>
          <div class="col-auto">
            <div class="card-info-sala-equipamentos">
              <a class="equipamentos-link" role="button" @click="setEquipments">
                <i class="fas fa-toolbox"></i> equipamentos
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: "CardRoom",
  props: ['item', 'current'],
  computed: {
    isSelected() {
      return this.current === this.item.id ? 'salaSelecionada' : ''
    },
    isChecked() {
      return this.current === this.item.id
    },
    ...mapGetters('auth', ['getBasePhoto'])
  },
  methods: {
    setSelected() {
      this.$emit('emmitSelected', this.item.id)
    },

    setEquipments() {
      this.$emit('emmitEquipments', this.item)
    }
  }
}
</script>

<style scoped>
  .card-info-sala-equipamentos a {
    color: #0d0d1b !important;
  }

  .salaSelecionada .card-info-sala-equipamentos a {
    color: #FFFFFF !important;
  }
</style>