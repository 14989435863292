<template>
  <div class="row text-left">
    <div class="col-md-12">
      <div class="card-conteudo">
        <div class="clearfix">
          <h4 class="float-left">PALESTRAS</h4>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12 mb-4">
            <h4>Salas do centro de evento</h4>


            <div class="col-md-12 p-0" style="overflow: auto !important;">
              <div class="box-lectures" :style="`width: ${lengthBoxLecture}px;`">
                <CardRoom
                    v-for="obj in room.data"
                    :key="obj.id"
                    :item="obj"
                    :current="room.current_id"
                    v-on:emmitSelected="setCurrentRoomSelected"
                    v-on:emmitEquipments="showEquipments"
                />
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-3" style="border-top: 1px solid #eeeeee;"></div>

          <div class="col-md-12 mt-2">
            <h4>Palestras adicionas <button class="btn btn-success btn-sm" @click="setNewLecture">+ nova palestra</button></h4>
            <div class="row boxPalestras">
              <CardLecture
                  v-for="le in lecture.data"
                  :key="le.id"
                  :item="le"
                  v-on:emmitEdit="setShowLecture"
                  v-on:emmitDelete="setDeleteLecture"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalEquipments :item="equipments" />
    <ModalLecture :item="lecture.current" v-on:emmitStore="setSave" />
  </div>
</template>

<script>
import CardRoom from "@/components/events/lecture/CardRoom"
import ModalEquipments from "@/components/events/lecture/ModalEquipments"
import CardLecture from "@/components/events/lecture/CardLecture";
import ModalLecture from "@/components/events/lecture/ModalLecture";
import {mapActions} from "vuex";
import setNotification from "@/config/showNotifications";

export default {
  name: "TabLecture",
  components: {ModalLecture, CardLecture, ModalEquipments, CardRoom},
  props: ['item'],
  data: () => ({
    room: {
      data: [],
      current_id: null
    },
    equipments: [],
    lecture: {
      store: [],
      data: [],
      current: []
    }
  }),
  created() {
    this.setValue()
  },
  computed: {
    lengthBoxLecture() {
      return this.room.data.length * 250
    }
  },
  methods: {
    ...mapActions('lecture', ['getDetailsLecture', 'setStoreLecture', 'setUpdateLecture', 'setDestroyLecture']),

    setValue() {
      let data = this.item
      if (data) {
        if (this.item.centro_evento.salas.length > 0) {
          this.room.current_id = this.item.centro_evento.salas[0].id
        }
        this.room.data          = this.item.centro_evento.salas
        this.lecture.store      = this.item.palestras
        this.filterLectures()
      }
    },

    filterLectures() {
      var data = []

      for (var w = 0; w < this.lecture.store.length; w ++) {
        var current = this.lecture.store[w]
        if (current.sala_id === this.room.current_id) {
          data.push(current)
        }
      }
      this.lecture.data = data.slice().sort((a, b) => b.inicio - a.inicio)
    },

    showEquipments(obj) {
      this.equipments = obj
      this.$bvModal.show('modal-equipments')
    },

    setCurrentRoomSelected(id) {
      this.room.current_id    = id
      this.filterLectures()
    },

    setNewLecture() {
      this.lecture.current = null
      this.$bvModal.show('modal-lecture')
    },

    async setShowLecture(obj) {
      let data = await this.getDetailsLecture({id: obj.id})
      if (data) {
        this.lecture.current = data[0]
        this.$bvModal.show('modal-lecture')
      } else {
        setNotification(this, 'error', 'Não é possível editar')
      }
    },

    setSave(obj) {
      if (obj.id === null) {
        this.store(obj)
      } else {
        this.update(obj)
      }
    },

    async store(obj) {

      obj.event_id  = this.item.id
      obj.room_id   = this.room.current_id

      let data = await this.setStoreLecture(obj)

      if (data.status === 'success') {
        setNotification(this, 'success', 'Palestra salva com sucesso')
        this.$bvModal.hide('modal-lecture')
        this.lecture.store.push(data.data[0])
      } else {
        setNotification(this, 'error', data.error)
      }

      this.filterLectures()
    },

    async update(obj) {

      obj.event_id  = this.item.id
      obj.room_id   = this.room.current_id

      let data = await this.setUpdateLecture(obj)

      if (data.status === 'success') {
        setNotification(this, 'success', 'Palestra salva com sucesso')
        this.$bvModal.hide('modal-lecture')

        var index = null

        for (var w = 0; w < this.lecture.store.length; w ++) {
          if (this.lecture.store[w].id === obj.id) {
            index = w
          }
        }
        this.lecture.store[index] = data.data[0]
      } else {
        setNotification(this, 'error', data.error)
      }

      this.filterLectures()

    },

    async setDeleteLecture(obj) {

      this.$swal.fire({
        title: 'Tem certeza?',
        text: "Esta palestra será excluida",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!'
      }).then((result) => {
        if (result.isConfirmed) {
          let data = this.setDestroyLecture({id: obj.id})

          if (data) {
            this.$swal.fire(
              'Excluido!',
              'A palestra foi excluida',
              'success'
            )

            this.setRemoveLectureObject(obj.id)

          } else {
            setNotification(this, 'error', 'Erro ao excluir palestra');
          }

        }
      })
    },

    setRemoveLectureObject(id) {
      var index = null
      for (var w = 0; w < this.lecture.store.length; w ++ ) {
        if (this.lecture.store[w].id === id) {
          index = w;
        }
      }

      if (index !== null) {
        this.lecture.store.splice(index, 1)
        this.filterLectures()
      }
    }
  }

}
</script>

<style scoped>
  .box-lectures {
    overflow-y: scroll !important;
    display: inline-block !important;
    min-width: 100%;
    background-color: #ececec;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 2px;
    border-radius: 2px;
  }
</style>